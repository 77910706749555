import React, { useState, useEffect} from "react";
import CircularLoading from "../components/CircularLoading";
import { SECONDORY_COLOR, downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import "./../assets/stilos.css";
/*const fetch = require("node-fetch");
const admin = require('firebase-admin'); */
import {
  Grid,
  Button,

} from "@mui/material";
import { useTranslation } from "react-i18next";
import AlertDialog from "../components/AlertDialog";
import { colors } from "../components/Theme/WebTheme";
import {
  ReferralsColumns,
} from "../common/sharedFunctions";
import { api } from 'common';
import { useSelector, useDispatch } from "react-redux";

const BookingHistory = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const role = useSelector((state) => state.auth.profile.usertype);
  const [data, setData] = useState([]);
  const [level, setLevel ] = useState("");
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const columns = ReferralsColumns(role, settings, t, isRTL);
 

  const dispatch  = useDispatch();
  const listlv1    = useSelector(state => state.usedreferralid);
  const sponsi      = useSelector(state => state.usedreferralid.elsposor);
  const [nivel, setNivel] = useState(1);
  const [patrocinador, setPatri] = useState("");
 


  async function busca_level2(nivel){
      const {
            listReferral2,
            listReferral3
      } = api;

      switch(nivel){
        case 2:   
                   if(listlv1.listRefer){ dispatch(listReferral2(listlv1.listRefer)); 
                      console.log("dispath level 2");
                   }
         break;
         case 3:   
                 if(listlv1.listRefer2){ dispatch(listReferral3(listlv1.listRefer2)); 
                      console.log("dispath level 3");
                 }
          break;
        }

  }

  const btn_level = async (valor) => {
    setData([]);
      switch(valor){
          case 1: 
                  setLevel("level 1");
                  setNivel(1);
                     if(listlv1.listRefer === null){
                            setData([]);
                      }else{
                             setData(listlv1.listRefer);
                      }
          break; 
          case 2: 
                  setLevel("level 2");
                  setNivel(2);
                     if(listlv1.listRefer2 === null){
                            setData([]);
                      }else{
                             setData(listlv1.listRefer2);
                      }
          break; 

          case 3: 
                  setLevel("level 3");
                  setNivel(3);
                     if(listlv1.listRefer3 === null){
                            setData([]);
                      }else{
                             setData(listlv1.listRefer3);
                      }
          break; 
         /// default: setData(listlv1.listRefer);
      }
   }

   const lista_referral = (value)=>{
                 
    console.log(" Nivel "+nivel);
    console.log(value)
    const {
      lista_id,
      lista_id2
    } = api;

    switch(nivel){
        case 1:  dispatch(lista_id(value));  console.log("level 1"); setNivel(2);    break;
        case 2:   dispatch(lista_id2(value));  console.log("level 2"); setNivel(3);  break;
        default: return;
    }
          

   }

useEffect(()=>{ 
  const {
           listReferral,
           sponsor
   } = api
        if(auth.profile){
              let id_cli = auth.profile.uid;
              //console.log(auth.profile);
                    if(listlv1.listRefer == null){
                              dispatch(listReferral(id_cli));
                              dispatch(sponsor(auth.profile.signupViaReferral))
                              setData([]);
                            
                    }else{
                          setLevel("level 1");
                          setData(listlv1.listRefer);
                    }
         }
},[listlv1.listRefer]);



useEffect(()=>{

  console.log(listlv1.listRefer2)

  if(nivel > 1){
    setLevel("Level "+nivel);
      if(listlv1.listRefer2 == null){
              setData([]);
      }else{
              setData(listlv1.listRefer2);
      }
  }
},[listlv1.listRefer2]);


useEffect(()=>{
  console.log(listlv1.listRefer3)

  if(nivel > 1){
    setLevel("Level "+nivel);
      if(listlv1.listRefer2 == null){
              setData([]);
      }else{
              setData(listlv1.listRefer3);
      }
  }
},[listlv1.listRefer3]);





const handleCommonAlertClose = (e) => {
  e.preventDefault();
  setCommonAlert({ open: false, msg: "" });
};
  const [selectedRow, setSelectedRow] = useState(null);



  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
            <div className="row mb-2">
                    <span className="">Sponsor : <span className="titu">{sponsi}</span></span>
            </div>

            <div className="row">
            <Grid  container spacing={2} style={{ padding: 10, paddingLeft:30, fontWeight:600,
                                          direction: isRTL === "rtl" ? "rtl" : "ltr",
                                          borderRadius: "8px",
                                          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`, }}>
                      

                  
                      <Grid  item xs={4} >
                             <Button  className="btn-self"
                                      onClick={() => btn_level(1)}  name = "1"  style={{ boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,   color:'#fff', backgroundColor:colors.BIDTAXIPRIMARY}} > Level 1</Button>
                      </Grid>
                      <Grid item xs={4}   >
                      <Button  className="btn-self" onClick={()=>btn_level(2)}   style={{ boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,   color:'#fff', backgroundColor:colors.BIDTAXIPRIMARY}} > Level 2</Button>
                      </Grid>
                      <Grid item xs={4}   >
                      <Button  className="btn-self" onClick={()=>btn_level(3)}    style={{ boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,   color:'#fff', backgroundColor:colors.BIDTAXIPRIMARY}} > Level 3</Button>
                      </Grid>
              
              </Grid>
      </div>


      <MaterialTable
        title={t("My_Referrals")+" "+level} 
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
        columns={columns}
        data={data}
        onRowClick={(evt, selectedRow) =>

                lista_referral(selectedRow.id)
                   //console.log(selectedRow.id)
                /* setSelectedRow(selectedRow.tableData.id) */
        }


        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          toolbarbuttonalignment: "right",
          exportCsv: (columns, data) => {
            let hArray = [];
            const headerRow = columns.map((col) => {
              if (typeof col.title === "object") {
                return col.title.props.text;
              }
              hArray.push(col.field);
              return col.title;
            });
            const dataRows = data.map(({ tableData, ...row }) => {
              row.bookingDate =
                new Date(row.bookingDate).toLocaleDateString() +
                " " +
                new Date(row.bookingDate).toLocaleTimeString();
              row.tripdate =
                new Date(row.tripdate).toLocaleDateString() +
                " " +
                new Date(row.tripdate).toLocaleTimeString();
              row.pickupAddress = row.pickupAddress.replace(/,/g, ";");
              row.dropAddress = row.dropAddress.replace(/,/g, ";");
              let dArr = [];
              for (let i = 0; i < hArray.length; i++) {
                dArr.push(row[hArray[i]]);
              }
              return Object.values(dArr);
            });
            const { exportDelimiter } = ",";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvFileName = "download.csv";
            downloadCsv(csvContent, csvFileName);
          },
          exportButton: {
            csv: settings.AllowCriticalEditsAdmin,
            pdf: false,
          },
          maxColumnSort: "all_columns",
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            border: "1px solid rgba(224, 224, 224, 1)",
          }),
          maxBodyHeight: "calc(100vh - 199.27px)",
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: SECONDORY_COLOR,
            color: colors.Black,
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            textAlign: "center",
            zIndex: 1,
            border: "1px solid rgba(224, 224, 224, 1)",
           
          },
          cellStyle: {
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
            margin: "auto",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
            exportCSVName: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}

      />

     
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default BookingHistory;
